// src/components/GameCard.js
import React from 'react';
import { Card, CardContent, Grid, Typography, Chip, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const CDN_URL = 'https://cdn.redditcfb.com/60x60/cfb/';

const GameCard = ({ game, viewMode }) => {
  const { id, source, status, homeTeam, awayTeam, score, tags: extraFilters, time, homeTags, awayTags, homeLineScores, awayLineScores, notes, livedata = {}, betting = {} } = game;
  const { tv, period, clock, situation, possession, lastPlay } = livedata;
  const { spread, overUnder } = betting;

  const getTimeComponent = () => {
    const gameDate = new Date(time);
    const now = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);
    const oneDayAgo = new Date();
    oneDayAgo.setDate(now.getDate() - 1);

    if (status === 'In Progress') {
      return '';
    } else if (status === 'Completed') {
      if (gameDate > oneDayAgo) {
        return 'Final';
      } else {
        return gameDate.toLocaleString('en-US', { month: 'short', day: 'numeric' });
      }
    } else if (status === 'Not Started') {
      return {
        date: gameDate.toLocaleString('en-US', { month: 'short', day: 'numeric' }),
        time: gameDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' })
      };
    } else {
      if (gameDate > now) {
        return gameDate.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' });
      } else if (gameDate > oneWeekAgo) {
        return 'Final';
      } else {
        return gameDate.toLocaleString('en-US', { month: 'short', day: 'numeric' });
      }
    }
  };

  const tagColors = {
    'Live!': '#0C7F0B',
    'Upset Watch': '#FFCDD2',
    'Upset!': '#B71C1C',
    'Shoot out!': '#FFA500',
    'Shutout!': '#800080',
    'Overtime!': '#000000',
    'Slug Fest': '#8B4513',
    'Big Points': '#FFFF00',
    'Nailbiter': '#FFDAB9'
  };

  const getTagColor = (tag) => tagColors[tag] || 'default';

  const getTagTextColor = (tag) => {
    const darkBackgroundTags = ['Live!', 'Upset!', 'Shutout!', 'Overtime!', 'Slug Fest'];
    if (darkBackgroundTags.includes(tag)) {
      return '#FFFFFF';
    }
    return '#000000';
  };

  const renderTeamInfo = (team, hasPossession, isHomeTeam) => (
    <Box sx={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
      {hasPossession && status !== 'Completed' && (
        <Box sx={{ 
          width: '8px', 
          height: '8px', 
          backgroundColor: '#FFD700', 
          borderRadius: '50%', 
          position: 'absolute', 
          left: isHomeTeam ? 'auto' : '-10px', 
          right: isHomeTeam ? '-10px' : 'auto' 
        }} />
      )}
      <img 
        src={`${CDN_URL}${team.logo}.png`} 
        alt={team.name} 
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
      />
    </Box>
  );

  const renderTeamName = (team) => (
    <Typography 
      variant="subtitle1" 
      align="center" 
      sx={{ 
        width: '80px',
        height: '16px',
        fontSize: '8px',
        lineHeight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '& > span': {
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }
      }}
    >
      <span>{team.name}</span>
    </Typography>
  );

  const renderScoreTable = () => {
    if (!homeLineScores || !awayLineScores || !Array.isArray(homeLineScores) || !Array.isArray(awayLineScores)) {
      return null;
    }

    try {
      const hasOT = homeLineScores.length > 5 || awayLineScores.length > 5;
      const quarters = ['1', '2', '3', '4'];
      const headerCells = ['Team', ...quarters];
      if (hasOT) headerCells.push('OT');
      headerCells.push('T');

      return (
        <TableContainer component={Box} sx={{ height: '50px', width: '284px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerCells.map((cell, index) => (
                  <TableCell key={index} align="center" sx={{ padding: '1px', fontSize: '0.525rem', lineHeight: '1rem', width: '20px' }}>
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[awayTeam, homeTeam].map((team, index) => (
                <TableRow key={index} sx={{ height: '12px' }}>
                  <TableCell align="center" sx={{ padding: '1px', lineHeight: '0.6rem', width: '20px' }}>
                    <Box sx={{ width: '12px', height: '12px', margin: 'auto' }}>
                      <img src={`${CDN_URL}${team.logo}.png`} alt={team.name} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </Box>
                  </TableCell>
                  {quarters.map((_, i) => (
                    <TableCell key={i} align="center" sx={{ padding: '1px', fontSize: '0.525rem', lineHeight: '0.6rem', width: '20px' }}>
                      {index === 0 ? (awayLineScores[i] !== undefined ? awayLineScores[i] : '') : (homeLineScores[i] !== undefined ? homeLineScores[i] : '')}
                    </TableCell>
                  ))}
                  {hasOT && (
                    <TableCell align="center" sx={{ padding: '1px', fontSize: '0.525rem', lineHeight: '0.6rem', width: '20px' }}>
                      {index === 0 ? (awayLineScores[4] !== undefined ? awayLineScores[4] : '') : (homeLineScores[4] !== undefined ? homeLineScores[4] : '')}
                    </TableCell>
                  )}
                  <TableCell align="center" sx={{ padding: '1px', fontSize: '0.525rem', lineHeight: '0.6rem', width: '20px' }}>
                    {index === 0 ? awayLineScores[awayLineScores.length - 1] : homeLineScores[homeLineScores.length - 1]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } catch (error) {
      console.error("Error rendering score table:", error);
      return null;
    }
  };

  const renderTags = (tags) => (
    <Box sx={{ width: '72px', height: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {tags && tags.division && (
        <Chip
          size="small"
          label={
            <Box display="flex" alignItems="center">
              <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>{tags.division}</Typography>
              {tags.conferenceImage && <img src={`${CDN_URL}${tags.conferenceImage}.png`} alt={tags.conference} style={{ height: '16px', marginLeft: '1px' }} />}
            </Box>
          }
          sx={{ fontSize: '0.6rem', height: '20px', margin: '0.5px' }}
        />
      )}
    </Box>
  );

  const renderExtraTags = (tags) => (
    <Box sx={{ width: '140px', height: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
      {tags && Array.isArray(tags) && tags.map((tag, index) => (
        <Chip 
          key={index} 
          label={tag} 
          size="small"
          sx={{ 
            margin: '0.5px', 
            backgroundColor: getTagColor(tag), 
            color: getTagTextColor(tag),
            fontSize: '0.6rem',
            height: '20px'
          }} 
        />
      ))}
    </Box>
  );

  const getScoreLine = (score, status, startTime) => {
    const now = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);
    const gameDate = new Date(startTime);
    if (status == 'Unknown') {
      return 'Unknown';
    }
    if (score.home === null && score.away === null) {
      if (gameDate < oneWeekAgo) {
        return 'Canceled';
      } else {
        return 'In Progress';
      }
    } else if ((score.home === 0 && score.away === 0) && status === 'Completed') {
      return 'Canceled';
    } else {
      return `${score.away} - ${score.home}`;
    }
  };

  return (
    <Card sx={{ 
      width: '300px', 
      height: viewMode === 'detailed' ? '225px' : '168.75px', 
      borderRadius: '8px', 
      backgroundColor: '#fbfbfb', 
    }}>
      <CardContent sx={{ 
        width: '284px',
        height: viewMode === 'detailed' ? '209px' : '152.25px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box sx={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '20px' }} />
          {renderTeamInfo(awayTeam, possession === 'away', false)}
          <Box sx={{ width: '20px' }} />
          <Box sx={{ width: '124px', height: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ height: '22.5px', display: 'flex', alignItems: 'center', fontSize: '0.5625rem', textAlign: 'center' }}>
              {viewMode === 'detailed' ? (notes || '') : ''}
            </Typography>
            {status === 'Not Started' ? (
              <>
                <Typography variant="h3" sx={{ height: '25px', display: 'flex', alignItems: 'center', fontSize: '1.0625rem' }}>
                  {getTimeComponent().date}
                </Typography>
                <Typography variant="h4" sx={{ height: '15px', display: 'flex', alignItems: 'center', fontSize: '0.75rem' }}>
                  {getTimeComponent().time}
                </Typography>
              </>
            ) : (
              <Typography variant="h3" sx={{ height: '25px', display: 'flex', alignItems: 'center', fontSize: '1.0625rem' }}>
                {status === 'Not Started' ? getTimeComponent() : getScoreLine(score, status, time)}
              </Typography>
            )}
            {status !== 'Not Started' && (
              <Typography variant="h5" sx={{ height: '15px', display: 'flex', alignItems: 'center', fontSize: '0.75rem' }}>
                {getTimeComponent()}
              </Typography>
            )}
            {status === 'In Progress' && (
              <>
                <Typography variant="h5" sx={{ height: '15px', display: 'flex', alignItems: 'center', fontSize: '0.65rem', textAlign: 'center' }}>
                  {clock ? clock : ''}{clock && period ? ', ' : ''}{period ? `${period}Q` : ''}
                </Typography>
                {situation && (
                  <Typography variant="h5" sx={{ width: '100%', height: '15px', alignItems: 'center', fontSize: '0.65rem', textAlign: 'center' }}>
                    {situation}
                  </Typography>
                )}
              </>
            )}
          </Box>
          <Box sx={{ width: '20px' }} />
          {renderTeamInfo(homeTeam, possession === 'home', true)}
          <Box sx={{ width: '20px' }} />
        </Box>
        <Box sx={{ width: '100%', height: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {renderTeamName(awayTeam)}
          <Box sx={{ width: '124px' }} />
          {renderTeamName(homeTeam)}
        </Box>
        <Box sx={{ height: '4px' }} />
        {renderScoreTable()}
        <Box sx={{ height: '4px' }} />
        {viewMode === 'detailed' && (
          <>
            {status === 'In Progress' && (
              <>
                {lastPlay && (
                  <Typography variant="h5" sx={{ width: '100%', height: '30px', display: 'flex', alignItems: 'center', fontSize: '0.7rem', textAlign: 'center' }}>
                    {lastPlay}
                  </Typography>
                )}
                <Box sx={{ width: '100%', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Chip
                    size="small"
                    label="Game Thread"
                    component="a"
                    href="https://old.reddit.com/r/CFB"
                    clickable
                    sx={{ fontSize: '0.6rem', height: '20px', margin: '0.5px' }}
                  />
                  {tv && (
                    <Chip
                      size="small"
                      label={tv}
                      component="a"
                      href="https://www.espn.com/watch"
                      clickable
                      sx={{ fontSize: '0.6rem', height: '20px', margin: '0.5px' }}
                    />
                  )}
                  {spread && (
                    <Chip
                      size="small"
                      label={<Box display="flex" alignItems="center"><span>{spread}</span><img src={`${CDN_URL}${homeTeam.logo}.png`} alt={homeTeam.name} style={{ width: '12px', height: '12px', objectFit: 'contain', marginLeft: '2px' }} /></Box>}
                      sx={{ fontSize: '0.6rem', height: '20px', margin: '0.5px', backgroundColor: '#D3D3D3' }}
                    />
                  )}
                  {overUnder && (
                    <Chip
                      size="small"
                      label={`O/U: ${overUnder}`}
                      sx={{ fontSize: '0.6rem', height: '20px', margin: '0.5px', backgroundColor: '#D3D3D3' }}
                    />
                  )}
                </Box>
              </>
            )}
            <Box sx={{ width: '100%', height: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {renderTags(awayTags)}
              {renderExtraTags(extraFilters)}
              {renderTags(homeTags)}
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default GameCard;
