// src/App.js
import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Select, MenuItem, FormControl, InputLabel, ToggleButton, ToggleButtonGroup, Tooltip, Box } from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import GameCard from './components/GameCard';
import axios from 'axios';

const App = () => {
  const BASE_URL = process.env.REACT_APP_API_URL || '';
  const [games, setGames] = useState([]);
  const [division, setDivision] = useState('');
  const [extraFilter, setExtraFilter] = useState('');
  const [year, setYear] = useState('');
  const [week, setWeek] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const startYear = 1995; // Hardcoded start year

  useEffect(() => {
    const fetchCurrentWeekYear = async () => {
      const response = await axios.get(`${BASE_URL}/api/current_week_year`);
      setYear(response.data.year);
      setCurrentYear(response.data.year);
      setWeek(response.data.week);
    };

    fetchCurrentWeekYear();
  }, []);
  const [viewMode, setViewMode] = useState('detailed');

  useEffect(() => {
    if (year && week !== null) {
      const fetchGames = async () => {
        const response = await axios.get(`${BASE_URL}/api/games?year=${year}&week=${week}`);
        setGames(response.data);
      };

      fetchGames();
    }
  }, [year, week]); 

  useEffect(() => {
    document.title = "/r/CFB Scoreboard";
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = 'https://www.redditcfb.com/favicon/favicon.ico'; 
    document.head.appendChild(link);
  }, []);

  const handleDivisionChange = (event) => {
    setDivision(event.target.value);
  };

  const handleExtraFilterChange = (event) => {
    setExtraFilter(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    setWeek(1);  // Reset week to 1 when year changes
  };

  const handleWeekChange = (event) => {
    setWeek(event.target.value);
  };

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const divisionOrder = ['FBS', 'FCS', 'D2', 'D3', 'NAIA', 'NJCAA', 'CCCAA', 'Canada', 'Mexico', 'Other'];
  const availableDivisions = divisionOrder.filter(div => 
    games.some(game => game.homeTags?.division === div || game.awayTags?.division === div)
  );

  const extraTags = Array.from(new Set(games.flatMap(game => game.tags || [])))
    .sort((a, b) => a.localeCompare(b));
  
  if (extraTags.includes('Live!')) {
    extraTags.splice(extraTags.indexOf('Live!'), 1);
    extraTags.unshift('Live!');
  }

  const filteredGames = games.filter(game => 
    (!division || game.homeTags?.division === division || game.awayTags?.division === division) &&
    (!extraFilter || game.tags?.includes(extraFilter))
  );

  const sortedGames = filteredGames.sort((a, b) => {
    const divisionOrder = ['FBS', 'FCS', 'D2', 'D3', 'NAIA', 'NJCAA', 'CCCAA', 'Canada', 'Mexico', 'Other'];
    const getDivisionIndex = (game) => divisionOrder.indexOf(game.homeTags?.division) !== -1 ? divisionOrder.indexOf(game.homeTags?.division) : divisionOrder.length;
    const isLive = (game) => game.tags?.includes('Live!') ? -1 : 1;

    if (isLive(a) !== isLive(b)) {
      return isLive(a) - isLive(b);
    } else if (getDivisionIndex(a) !== getDivisionIndex(b)) {
      return getDivisionIndex(a) - getDivisionIndex(b);
    } else {
      return new Date(a.time) - new Date(b.time);
    }
  });

  return (
    <Container sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh', padding: '20px', maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: viewMode === 'detailed' ? '1320px' : '100%' } }}>
      <Typography variant="h4" align="center" sx={{ marginTop: '20px', marginBottom: '20px' }}>
        /r/CFB Scoreboard
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="year-select-label" shrink={year !== ''}>Year</InputLabel>
            <Select
              labelId="year-select-label"
              value={year}
              label="Year"
              onChange={handleYearChange}
            >
              {Array.from({ length: (currentYear - startYear + 1) }, (_, i) => currentYear - i).map((y) => (
                <MenuItem key={y} value={y}>{y}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="week-select-label">Week</InputLabel>
            <Select
              labelId="week-select-label"
              value={week}
              label="Week"
              onChange={handleWeekChange}
            >
              <MenuItem value={0}>Week 0</MenuItem>
              {Array.from({ length: 17 }, (_, i) => i + 1).map((w) => (
                <MenuItem key={w} value={w}>{w === 17 ? 'Postseason' : `Week ${w}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="division-select-label">Division</InputLabel>
            <Select
              labelId="division-select-label"
              value={division}
              label="Division"
              onChange={handleDivisionChange}
            >
              <MenuItem value=""><em>All</em></MenuItem>
              {availableDivisions.map((div) => (
                <MenuItem key={div} value={div}>{div}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="extra-filter-select-label">Extra Filters</InputLabel>
            <Select
              labelId="extra-filter-select-label"
              value={extraFilter}
              label="Extra Filters"
              onChange={handleExtraFilterChange}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {extraTags.map((tag) => (
                <MenuItem key={tag} value={tag}>{tag}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
          >
            <ToggleButton value="simplified" aria-label="simplified view">
              <Tooltip title="Simplified View">
                <ViewModuleIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="detailed" aria-label="detailed view">
              <Tooltip title="Detailed View">
                <ViewListIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2} justifyContent="center" sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: viewMode === 'detailed' ? '1320px' : '100%' } }}>
          {sortedGames.map((game, index) => (
            <Grid item key={index} sx={{ display: 'flex', justifyContent: 'center', padding: '8px !important'}}>
              <GameCard game={game} viewMode={viewMode} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default App;
